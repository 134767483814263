import React, { useMemo, useRef, useState, useContext, useCallback } from 'react';
import {ElementContext} from './ElementContext'
import phone from 'phone';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';


class flagCountryIndicator {
  init(params) {
      const element = document.createElement('span');
      const imageElement = document.createElement('img');
      const countryElement = document.createElement('span');

      if(params.value){
        imageElement.src = `https://flagcdn.com/${(params.value).toLowerCase()}.svg`;
        imageElement.width = '25';
        imageElement.className = 'me-2'
        
        element.appendChild(imageElement);
        element.appendChild(countryElement);
        element.appendChild(document.createTextNode(params.value));
        this.eGui = element;
      }
  }
  getGui() {
      return this.eGui;
  }
}



const ContactInfo = () => {

  const { infoToShow } = useContext(ElementContext);
  const { t } = useTranslation();

  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ height: '254px' }), []);
  const gridStyle = useMemo(() => ({ height: '100%' }), []);

  const [columnDefs] = useState([
    { headerName: 'ID', field: 'idcontacto' },
    { headerName: t('name'), field: 'nombre' },
    { headerName: t('lastname'), field: 'apellido' },
    {
      headerName: t('country'),
      field: 'pais',
      valueGetter: (params) => {
        const verify = params.data.numero.slice(0, 3) === '503' ? {countryIso2:'SV'} : phone(`+${params.data.numero}`)
        return verify.countryIso2
      },
      cellRenderer: flagCountryIndicator
    },
    { headerName: t('company'), field: 'empresa' },
    { headerName: t('position'), field: 'cargo' },
    { headerName: t('number'), field: 'numero', width: 150, pinned: 'left' },
    { headerName: t('observations'), field: 'observaciones' },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1, sortable: true, filter: true
    };
  }, []);  

  
  const sizeToFit = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  
  const onGridReady = useCallback((params) => {
    sizeToFit()
    autoSizeAll()
  }, [sizeToFit, autoSizeAll]);

  return (
    <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={infoToShow}
                rowHeight={35}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                suppressClipboardPaste={false}
                suppressAggFuncInHeader={true}
                enableCellChangeFlash={true}
                onGridReady={onGridReady}
            ></AgGridReact>
        </div>
    </div>
  );
};

export default ContactInfo;