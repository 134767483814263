import React, { useState } from 'react';
import countries from 'i18n-iso-countries';
import { fetchFronteggSignUp, fetchWebhookSignUp } from '../helpers/fronteggfetch'
import { DateTime } from 'luxon'
import { apiBaseUrl, fetchBackend } from '../helpers/fetch'
import { useEffect } from 'react'
import PaymentDoneMessage from './PaymentDoneMessage'
import Swal from 'sweetalert2'
import Select from "react-select";
import { validarEmail } from '../helpers/validations';
import { useTranslation } from 'react-i18next';
import { getGlobalEnv } from '../helpers/globalSettings';
import '../styles/newuserpage.css';


const timeZone = DateTime.now().zoneName
let helpURL;
let activationMethod;

const queryPlan = new URLSearchParams(window.location.search);
const selectedPlan = queryPlan.get("plan") || 'trial'
const invalidPlanSelected = queryPlan.get("plan") !== 'trial' && queryPlan.get("plan") !== 'personal' && queryPlan.get("plan") !== 'pro' && queryPlan.get("plan") !== 'business' && queryPlan.get("plan") !== 'enterprise' && queryPlan.get("plan") !== null

const planInterval = queryPlan.get("interval") || 'month'
const invalidInterval = (planInterval !== 'month') && (planInterval !== 'year')


const CustomSignUpForm = () => {
  
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState('');
  const [loadingRedirect, setLoadingRedirect] = useState(false);

  const [globalEnv, setGlobalEnv] = useState(null)
  const [userName, setUserName] = useState('')
  const [userLastname, setUserLastname] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userEmailVerify, setUserEmailVerify] = useState('')
  const [userCompany, setUserCompany] = useState('')
  const [userPhone, setUserPhone] = useState('')
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [loadingButton, setLoadingButton] = useState(false)
  const [showErrorMsg, setShowErrorMsg] = useState(false)
  const [countryOptions, setCountryOptions] = useState()
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false)


  const emailVerificationInput = document.querySelector('#newUserEmailVerify');

  // Setea la información del usuario según el input en el que está escribiendo
  const handleNewUserInfoChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name': setUserName(value)
        break;
      case 'lastname': setUserLastname(value)
        break;
      case 'email':
        setUserEmail(value)
        if(value === userEmailVerify){
          setShowErrorMsg(false)
        }
        break;
      case 'company': setUserCompany(value)
        break;
      case 'phone': setUserPhone(value)
        break;
      default:
        break;
    }
  }

  // Verificar email del usuario
  const handleNewUserEmailVerify = (e) => {
    setUserEmailVerify(e.target.value)
    if(e.target.value === userEmail){
      setShowErrorMsg(false)
    }
  }

  // Verifica la coincidencia del Email cuando otro input tiene el foco
  const emailInputVerified = () => {
    if(userEmail === userEmailVerify){
      setShowErrorMsg(false)
    } else {
      setShowErrorMsg(true)
      emailVerificationInput.focus()
    }
  }
  

  // Manejo del form submit
  const handleSubmitRegisterForm = async(e) => {
    try {
      e.preventDefault();

      // Validar que la confirmación del email sea igual al campo "Email"
      if(userEmail !== userEmailVerify){
        setShowErrorMsg(true)
        emailVerificationInput.focus()
        return
      }

      // No permitir campo "País" vacío
      if(!selectedCountry){
        return Swal.fire(t('warning'), t('selectCountry'), 'warning')
      }

      // Validar que sea un mail válido y no tenga tildes
      const emailValidation = await validarEmail(userEmail)
      if(!emailValidation){ return }
      
      setLoadingButton(true)

      // Chequear si el mail ingresado ya existe en BD
      const emailRequest = await fetchBackend('tenants');
      const emailResponse = await emailRequest.json();
      const emailAlreadyExists = await emailResponse.data.some( row => row.usuario === userEmail )

      if(invalidPlanSelected){
        setLoadingButton(false)
        return Swal.fire(t('warning'), t('invalidPlan'), 'warning')
      }
      if(invalidInterval){
        setLoadingButton(false)
        return Swal.fire(t('warning'), t('invalidInterval'), 'warning')
      }

      
      const newTenant = userEmail.replace('@', '_');
      const data = {
          name: userName,
          lastname: userLastname,
          email: userEmail,
          company: userCompany,
          phone: userPhone,
          country: selectedCountry.label,
          tenant: newTenant,
          fecha: DateTime.now().setZone(timeZone),
          plan: selectedPlan,
          pago: false,
          terminosaceptados: true,
          avisoslegales: false,
          language: i18n.resolvedLanguage,
          CLIENT_ID: globalEnv.REACT_APP_FRONTEGG_CLIENTID,
          API_KEY: globalEnv.REACT_APP_FRONTEGG_API_KEY,
          fronteggVendorHost: globalEnv.REACT_APP_FRONTEGG_VENDOR_HOST,
          interval: planInterval
      }

      
      if(emailAlreadyExists){
        setLoadingButton(false)
        return Swal.fire(t('warning'), t('emailSignedUp'), 'warning')
      }

  
      // Guardado en frontegg
      if(activationMethod === 'frontegg'){ await fetchFronteggSignUp(data) }
      else { await fetchWebhookSignUp(data) }
      

      // Guardado en BD | Tabla tenants
      const postTenant = await fetchBackend(`tenants`, data, 'POST');
      await postTenant.json();

  
      // Guardado en DB | Tabla usuarios
      const postNewUser = await fetchBackend('usuarios', data, 'POST');
      await postNewUser.json();
  
      
      // Guardado en DB | Tabla configuraciones
      const init = {
        delay: 5,
        oscilacionTiempo: false,
        language: i18n.resolvedLanguage
      }
  
      const basicConfiguration = {
          email: userEmail,
          timeZone,
          settings: JSON.stringify(init),
          fechacreacion: DateTime.now().setZone(timeZone)
      }
      
      const postDefaultSettings = await fetchBackend('settings', {basicConfiguration}, 'POST');
      await postDefaultSettings.json();


      if( selectedPlan === 'trial' ){
          setMessage(t('sigUpThanks'))
      } else {
          if(selectedCountry && globalEnv.MP_COUNTRIES_ENABLED.includes(selectedCountry.value)){
            // Actualiza el "external_reference" de Mercado Pago para poder recuperar esta información cuando el Webhook sea disparado
            await fetchBackend('update-mp-plan', {selectedPlan, userEmail}, 'PUT')
            
            const button = document.querySelector('#payment-button-mercadopago')
            button.click();
          } else {
            const button = document.querySelector('#payment-button')
            button.click();
          }
      }

    } catch (error) {
      console.log(error)
    }
  }

  useEffect( () => {
    const getEnv = async() => {
      const getEnvData = await getGlobalEnv();
      setGlobalEnv(getEnvData)

      helpURL = getEnvData.REACT_APP_HELP_LINK;
      activationMethod = getEnvData.REACT_APP_ACTIVATION_METHOD;
    }
    getEnv()
  }, [])


  useEffect(() => {
    const getCountries = async() => {
      const countriesJson = countries.getNames(i18n.resolvedLanguage)

      let keysArray = [];
      let valuesArray = [];
      for(let key in countriesJson){
        keysArray.push(key);
      }
      for(let value in countriesJson){
        valuesArray.push(countriesJson[value]);
      }

      const countriesArray = [];
      for(let i=0; i<valuesArray.length; i++){
        countriesArray.push({ value:keysArray[i], label:valuesArray[i] })
      }
      
      setCountryOptions(countriesArray)
    }
    getCountries();
  }, [])
  

  // A la escucha de cambios en la URL
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if(window.location.href.includes('redirect') && (i18n.resolvedLanguage === 'es')){
      setLoadingRedirect(true)
      window.location.href = 'https://seidoranalytics.com/harbinger';
    } else if (window.location.href.includes('redirect') && (i18n.resolvedLanguage === 'en')){
      setLoadingRedirect(true)
      window.location.href = 'https://seidoranalytics.com/harbinger-en';
    }

    if (query.get("success")) {
      console.log('Payment Success')
      setMessage(t('paymentSucces'));
    }

    if (query.get("canceled")) {
      setMessage(t('paymentCanceled'));
    }
  }, []);


  if(loadingRedirect){
      return <div style={{ "height": "100vh" }} className='text-center lead d-flex align-items-center justify-content-center text-mediumblue'>
                <p className='mb-0'>{t('redirect')}...</p>
                
                <div className="spinner-grow ms-3" role="status">
                    <span className="visually-hidden">...</span>
                </div>
            </div>
  }


  return ( message ? (
    <div style={{ "height": "100vh" }} className='d-flex flex-column align-items-center justify-content-center'>
      <div style={{ "width": "100%" }}>
          <div className='harbinger-logo-azul'></div>
          <h5 className='text-center mt-4'>{message}</h5>
      </div>

        {
          message.includes('registrarte') || message.includes('realizado') || message.includes('joining') || message.includes('success') ? (
            <div id='sign-up-success' className='mt-3'>
                <PaymentDoneMessage />
            </div>
          ) : null
        }

        {
          message.includes('cancelada') || message.includes('canceled') ? (
            <span
              className='text-decoration-none text-mediumblue mt-2 pointer'
              onClick={ () => setMessage('') }
            >
              {t('backToSingUp')}
            </span>
          ) : null
        }
    </div>
  ) : (
    <div>
        <div className='harbinger-logo-azul'></div>

        <div className='d-flex flex-column justify-content-center align-items-center'>
          <h2 className='signUpPageTitle mb-4'>{t('userSignUp')}</h2>

          <h6 className='mb-2 color-login'>
              {t('yourPlanRegistration')}
              <strong className='ms-1'>{selectedPlan.toUpperCase()}</strong>
          </h6>
          

          {/* Redirect a Login page */}
          <small className='mb-4 color-login'>
              {t('alreadySignedUp')} <a href="/account/login" className='text-decoration-none text-mediumblue'>
                {t('login')}</a>
          </small>


          <form className='newUserForm' onSubmit={ handleSubmitRegisterForm }>
            <label htmlFor="newUserName">{t('name')}</label>
            <input
              autoComplete='off'
              className='form-control mb-2'
              type="text"
              name="name"
              id="newUserName"
              placeholder={t('enterYourName')}
              value={ userName }
              onChange={ handleNewUserInfoChange }
              onFocus={ emailInputVerified }
              required
            />


            <label htmlFor="newUserLastname">{t('lastname')}</label>
            <input
              autoComplete='off'
              className='form-control mb-2'
              type="text"
              name="lastname"
              id="newUserLastname"
              placeholder={t('enterYourLastname')}
              value={ userLastname }
              onChange={ handleNewUserInfoChange }
              onFocus={ emailInputVerified }
              required
            />


            <label htmlFor="newUserEmail">{t('email')}</label>
            <input
              autoComplete='off'
              className='form-control mb-2'
              type="email"
              name="email"
              id="newUserEmail"
              placeholder={t('enterYourEmail')}
              value={ userEmail }
              onChange={ handleNewUserInfoChange }
              required
            />
            
            <label htmlFor="newUserEmailVerify" className='mb-0'>{t('confirmEmail')}</label>
            {
              showErrorMsg ? (
                <div className="d-block">
                    <small className='d-flex align-items-center text-danger'>
                        <i className='bx bx-x x-size'></i>
                        {t('emailsDontMatch')}
                    </small>
                </div>
              ) : null
            }
            <input
              autoComplete='off'
              className='form-control mb-2'
              type="email"
              name="email"
              id="newUserEmailVerify"
              placeholder={t('enterYourEmail')}
              value={userEmailVerify}
              onChange={ handleNewUserEmailVerify }
              required
            />

            <label htmlFor="newUserCompany">{t('company')}</label>
            <input
              autoComplete='off'
              className='form-control mb-2'
              type="text"
              name="company"
              id="newUserCompany"
              placeholder={t('enterYourCompany')}
              value={ userCompany }
              onChange={ handleNewUserInfoChange }
              onFocus={ emailInputVerified }
              required
            />

            <label htmlFor="newUserPhone">{t('number')}</label>
            <input
              autoComplete='off'
              className='form-control mb-2'
              type="tel"
              name="phone"
              id="newUserPhone"
              placeholder={t('enterYourPhone')}
              value={ userPhone }
              onChange={ handleNewUserInfoChange }
              onFocus={ emailInputVerified }
              required
            />

            {/* Input País */}
            <label htmlFor="newUserCountry">{t('country')}</label>
            <Select
              defaultValue={selectedCountry}
              onChange={setSelectedCountry}
              options={countryOptions}
              placeholder={t('selectYourCountry')}
            />

            <div className='mt-5'>
              <div className='d-flex'>
                <input type="checkbox" className='mt-1 me-2' name='conditionsandpolicy' id='conditionsandpolicy' onChange={ (e) => setTermsAndConditionsChecked(e.target.checked) } />
                <span className='termsandpolicy'>
                  {t('termsAndConditionsCheck')} <a href={ i18n.resolvedLanguage === 'es' ? `${helpURL}/termsandconditions` : `${helpURL}/en/termsandconditions` } target='_blank' rel="noopener noreferrer" className='pointer text-decoration-none text-mediumblue'>{t('serviceConditions')}</a> {t('and')} <a href={ i18n.resolvedLanguage === 'es' ? `${helpURL}/termsandconditions/privacy.html` : `${helpURL}/en/termsandconditions/privacy.html` } target='_blank' rel="noopener noreferrer" className='pointer text-decoration-none text-mediumblue'>{t('privacyPolicies')}</a>
                </span>
              </div>
            </div>


            {/* Guardado de info en BD */}
            <button
              type="submit"
              className='btn btn-mediumblue btn-check-width btn-sm my-5'
              disabled={ loadingButton || !termsAndConditionsChecked }
            >
              {
                loadingButton ? (
                  <div>
                    <span className='text-light'>{t('processing')}...</span>
                    <div className="spinner-border text-light loading-button ms-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : t('signUp')
              }
            </button>
          </form>
          

          {/* Form que acciona el link de pago | Oculto para el usuario */}
            <form
              action={
                selectedCountry && (selectedCountry.value === 'MX')
                ? `${apiBaseUrl}/api/payment-${selectedPlan}-mex`
                :  `${apiBaseUrl}/api/payment-${selectedPlan}`
              }
              method="POST">
                <input
                  className='d-none'
                  type="email"
                  name="customeremail"
                  id="customeremail"
                  value={userEmail}
                  onChange={ () => console.log(userEmail) }
                />
                <input
                  className='d-none'
                  type="text"
                  name="interval"
                  id="interval"
                  value={planInterval}
                  onChange={ () => console.log(userEmail) }
                />
                <button id='payment-button' type="submit" className="d-none">
                    {t('pay')}
                </button>
            </form>
            
            <a
              href={
                (selectedPlan === 'personal' &&
                `https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848823e7510188299d1ff60237`)
                || (selectedPlan === 'pro' &&
                `https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c93808487fe91120188059ccc5a0281`)
                || (selectedPlan === 'business' &&
                `https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848823e7510188299f15b20238`)
              }
              id='payment-button-mercadopago'
              rel="noopener noreferrer"
              type="button"
              className='d-none'
            >
                Pagar con Mercado Pago
            </a>
        </div>
    </div>
  )
  )
}

export default CustomSignUpForm