import React, { useContext, useState } from 'react'
import { apiBaseUrl } from '../../helpers/fetch';
import { ElementContext } from '../ElementContext';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@frontegg/react';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { FormControlLabel } from '@mui/material';
import { numberObject } from '../../helpers/utils';
import 'animate.css';
import { useEffect } from 'react';


const PlanCards = () => {
    
  const { user } = useAuth();
  const { subscriptionId, userInformation, globalEnv, recurringSelected, setRecurringSelected } = useContext(ElementContext);
  const { t } = useTranslation();
  const [newSelectedPlan, setNewSelectedPlan] = useState(null)

  localStorage.setItem('subscriptionID', subscriptionId)
  localStorage.setItem('userIsoCode', userInformation.isoCode)

    const startUpgradePlan = async(plan) => {
        setNewSelectedPlan(await plan)
        await document.querySelector('#payment-button').click()
    }

    useEffect(() => {
        document.querySelector('#btn-year').click()
    }, [])
    

  return (
    <div className='container'>
        <div className="row mb-5">
            <div className="col-12 d-flex justify-content-center align-items-center plan-switch">
                <div className="btn-group w-25" role="group" aria-label="Basic radio toggle button group">
                    <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio1"
                        onChange={ () => setRecurringSelected('month') }
                    />
                    <label id='btn-month' className="btn btn-outline-primary" htmlFor="btnradio1">{ t('monthly2') }</label>

                    <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio3"
                        onChange={ () => setRecurringSelected('year') }
                    />
                    <label id='btn-year' className="btn btn-outline-primary" htmlFor="btnradio3">{ t('annually') }</label>
                </div>
            </div>
        </div>

        <div className="row d-flex justify-content-center">

            <div className="col-3 d-flex flex-column align-items-center plan-cards">
                  <div className='d-flex flex-column justify-content-center align-items-center w-100 border-bottom pb-3'>
                        <p className='text-lightblue'>PERSONAL</p>
                        <i className='bx bxs-user text-lightblue'></i>
                        <div className='price-info'>
                            {
                                (recurringSelected === 'year') && (
                                    <s className='animate__animated animate__heartBeat'>
                                        { (parseInt(globalEnv.PRICES['month']['personal'][userInformation.isoCode]?.price
                                                 || globalEnv.PRICES['month']['personal']['US'].price)) }
                                    </s>
                                )
                            }
                            <h1 className='price-font m-0 ms-1'>
                                {
                                    (parseInt(globalEnv.PRICES[recurringSelected]['personal'][userInformation.isoCode]?.price
                                    || globalEnv.PRICES[recurringSelected]['personal']['US'].price))
                                }
                            </h1>
                            <div className='price-currency'>
                                <small>/{ t('month') }</small>
                                <small className='price-font'>
                                    {
                                        globalEnv.PRICES[recurringSelected]['personal'][userInformation.isoCode]?.currency
                                        || globalEnv.PRICES[recurringSelected]['personal']['US'].currency
                                    }
                                </small>
                            </div>
                        </div>
                  </div>

                  <div className='pt-3'>
                      <small>{ t('includes') }:</small>
                      <ul className='text-91'>
                          <li>1 {t('user')}</li>
                          <li>2000 {t('messages')}</li>
                      </ul>
                  </div>
                  
                  {
                      globalEnv.MP_COUNTRIES_ENABLED.includes(userInformation.isoCode) ? (
                          <a
                            href='https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848823e7510188299d1ff60237'
                            type="button"
                            className="btn btn-outline-mediumblue btn-sm mt-auto"
                          >
                              {t('payment')}
                          </a>
                      ) : (
                          <button
                              type="submit"
                              className="btn btn-outline-mediumblue btn-sm mt-auto"
                              onClick={ () => startUpgradePlan('personal') }
                          >
                              {t('select')}
                          </button>
                      )
                  }
            </div>

            <div className="col-3 d-flex flex-column align-items-center plan-cards">
                  <div className='d-flex flex-column justify-content-center align-items-center w-100 border-bottom pb-2'>
                        <p className='text-lightblue'>PRO</p>
                        <i className='bx bxs-bar-chart-alt-2 text-lightblue'></i>
                        <div className='price-info'>
                            {
                                (recurringSelected === 'year') && (
                                    <s className='animate__animated animate__heartBeat'>
                                        { (parseInt(globalEnv.PRICES['month']['pro'][userInformation.isoCode]?.price
                                                 || globalEnv.PRICES['month']['pro']['US'].price)) }
                                    </s>
                                )
                            }
                            <h1 className='price-font m-0 ms-1'>
                                {
                                    (parseInt(globalEnv.PRICES[recurringSelected]['pro'][userInformation.isoCode]?.price
                                    || globalEnv.PRICES[recurringSelected]['pro']['US'].price))
                                }
                            </h1>
                            <div className='price-currency'>
                                <small>/{ t('month') }</small>
                                <small className='price-font'>
                                    {
                                        globalEnv.PRICES[recurringSelected]['pro'][userInformation.isoCode]?.currency
                                        || globalEnv.PRICES[recurringSelected]['pro']['US'].currency
                                    }
                                </small>
                            </div>
                        </div>
                  </div>

                  <div className='pt-3'>
                      <small>{ t('includes') }:</small>
                      <ul className='text-91'>
                          <li>1 {t('user')}</li>
                          <li>4000 {t('messages')}</li>
                          <li>{t('scheduleMsgs')}</li>
                      </ul>
                  </div>

                  {
                      globalEnv.MP_COUNTRIES_ENABLED.includes(userInformation.isoCode) ? (
                          <a
                            href='https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c93808487fe91120188059ccc5a0281'
                            type="button"
                            className="btn btn-mediumblue btn-sm mt-auto"
                          >
                              {t('payment')}
                          </a>
                      ) : (
                          <button
                              type="submit"
                              className="btn btn-outline-mediumblue btn-sm mt-auto"
                              onClick={ () => startUpgradePlan('pro') }
                          >
                              {t('select')}
                          </button>
                      )
                  }
            </div>

            <div className="col-3 d-flex flex-column align-items-center plan-cards">
              <div className='price-details w-100 border-bottom pb-2'>
                    <p className='text-lightblue'>BUSINESS</p>
                    <i className='bx bxs-briefcase text-lightblue'></i>
                    <div className='price-info'>
                        {
                            (recurringSelected === 'year') && (
                                <s className='animate__animated animate__heartBeat'>
                                    { (parseInt(globalEnv.PRICES['month']['business'][userInformation.isoCode]?.price
                                                || globalEnv.PRICES['month']['business']['US'].price)) }
                                </s>
                            )
                        }
                        <h1 className='price-font m-0 ms-1'>
                            {
                                (parseInt(globalEnv.PRICES[recurringSelected]['business'][userInformation.isoCode]?.price
                                || globalEnv.PRICES[recurringSelected]['business']['US'].price))
                            }
                        </h1>
                        <div className='price-currency'>
                            <small>/{ t('month') }</small>
                            <small className='price-font'>
                                {
                                    globalEnv.PRICES[recurringSelected]['business'][userInformation.isoCode]?.currency
                                    || globalEnv.PRICES[recurringSelected]['business']['US'].currency
                                }
                            </small>
                        </div>
                    </div>
              </div>

              <div className='pt-3'>
                  <small>{ t('includes') }:</small>
                  <ul className='text-91'>
                      <li>1 {t('user')}</li>
                      <li>{numberObject.format(10000)} {t('messages')}</li>
                      <li>{t('scheduleMsgs')}</li>
                      <li>{t('chatgptIntegration')}</li>
                  </ul>
              </div>
                  
                  {
                      globalEnv.MP_COUNTRIES_ENABLED.includes(userInformation.isoCode) ? (
                          <a
                            href='https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848823e7510188299f15b20238'
                            type="button"
                            className="btn btn-mediumblue btn-sm mt-auto"
                          >
                              {t('payment')}
                          </a>
                      ) : (
                          <button
                              type="submit"
                              className="btn btn-outline-mediumblue btn-sm mt-auto"
                              onClick={ () => startUpgradePlan('business') }
                          >
                              {t('select')}
                          </button>
                      )
                  }
            </div>

            <div className="col-3 d-flex flex-column align-items-center plan-cards">
              <div className='d-flex flex-column justify-content-center align-items-center w-100 border-bottom pb-2'>
                  <p className='text-lightblue'>ENTERPRISE</p>
                  <i className='bx bxs-business text-lightblue'></i>
                  <h5 className='price-font pt-1 pb-2'>{t('contactUs')}</h5>
              </div>

              <div className='pt-3'>
                  <small>{ t('includes') }:</small>
                  <ul className='text-91'>
                      <li>5 {t('users')}</li>
                      <li>+{numberObject.format(100000)} {t('messages')}</li>
                      <li>{t('scheduleMsgs')}</li>
                      <li>{t('chatgptIntegration')}</li>
                      <li>{t('systemIntegration')}</li>
                      <li>{t('privateCloud')}</li>
                  </ul>
              </div>
                <a
                  href='https://global.seidoranalytics.com/harbingerenterprise'
                  target='_blank'
                  rel="noopener noreferrer"
                  className='btn btn-sm btn-outline-mediumblue mt-auto'
                >
                    {t('select')}
                </a>
            </div>

        </div>

        <div className="row">
              {/* Form que acciona el link de pago | Oculto para el usuario */}
              <form
                  action={
                      (userInformation.isoCode === 'MX')
                      ? `${apiBaseUrl}/api/payment-${newSelectedPlan}-mex`
                      : `${apiBaseUrl}/api/payment-${newSelectedPlan}`
                  }
                  method="POST">
                  <input
                      className='d-none'
                      type="email"
                      name="customeremail"
                      id="customeremail"
                      value={user.email}
                      onChange={ () => console.log(user.email) }
                  />
                  <input
                      className='d-none'
                      type="text"
                      name="interval"
                      id="interval"
                      value={recurringSelected}
                      onChange={ () => console.log(recurringSelected) }
                  />
                  <button
                      id='payment-button'
                      type="submit"
                      className="d-none"
                  >
                      {t('pay')}
                  </button>
              </form>
        </div>
    </div>
  )
}

export default PlanCards