import React, { useCallback, useMemo, useState, useRef, useContext } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate, ReactEditor, useSelected, useFocused } from 'slate-react'
import { Editor, Transforms, createEditor, Element as SlateElement, Range, Value } from 'slate'
import { withHistory } from 'slate-history'
import { Button, Icon, Portal, Toolbar } from './SlateComponents'
import { useEffect } from 'react'
import EmojiPicker, { Emoji } from 'emoji-picker-react'
import { ElementContext } from './ElementContext'
import { useTranslation } from 'react-i18next'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list', 'list-item']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']


const RichTextExample = () => {
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  
  const ref = useRef()
  const [target, setTarget] = useState()
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')
  const editor = useMemo( () => withMentions(withReact(withHistory(createEditor()))), [] )
  const { emojiSelect, setEmojiSelect, setGlobalEditorChildren, setMessage } = useContext(ElementContext);
  const { t } = useTranslation();

  const enterSomeText = t("enterSomeText");

  const chars = CHARACTERS

  const onEmojiClick = (emojiData) => {
    const { emoji, unified, getImageUrl } = emojiData
    insertMention(editor, emoji, unified)
    setEmojiSelect({ showBox: false })
  }

  const onKeyDown = useCallback(
    event => {
      if (target && chars.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault()
            const prevIndex = index >= chars.length - 1 ? 0 : index + 1
            setIndex(prevIndex)
            break
          case 'ArrowUp':
            event.preventDefault()
            const nextIndex = index <= 0 ? chars.length - 1 : index - 1
            setIndex(nextIndex)
            break
          case 'Tab':
          case 'Enter':
            event.preventDefault()
            Transforms.select(editor, target)
            insertMention(editor, chars[index])
            setTarget(null)
            break
          case 'Escape':
            event.preventDefault()
            setTarget(null)
            break
        }
      }
      
      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event)) {
          event.preventDefault()
          const mark = HOTKEYS[hotkey]
          toggleMark(editor, mark)
        }
      }
    },
    [chars, editor, index, target]
  )
  
  // Función para obtener el texto completo del editor
  const getTextFromEditor = (children) => {
    let fullText = '';
    for(let array of children){
      const elements = array.children;

      if(array.type === 'bulleted-list'){
        let bulletedString = '  •  '
        for(let part of array.children){
          const subElements = part.children
          for(let elem of subElements){
            if(elem.character){
              bulletedString += elem.character
            } else {
              bulletedString += elem.text
            }
          }
        }
        fullText += bulletedString

      } else {
        for(let part of elements){
          if(part.text && part.bold){
            const format = ` *${part.text.trim()}* `
            fullText += format
          } else if(part.text && part.italic){
            const format = ` _${part.text.trim()}_ `
            fullText += format
          } else if(part.text && part.underline){
            const format = ` ~${part.text.trim()}~ `
            fullText += format
          } else if(part.character && CHARACTERS.includes(part.character)){
            fullText += ` $${part.character}$`
          } else if(part.character){
            fullText += part.character
          } else {
            fullText += part.text
          }
        }
      }
      fullText += '\n';
    }
    setMessage(fullText)
  };
  
  useEffect(() => {
    if (target && chars.length > 0) {
      const el = ref.current
      const domRange = ReactEditor.toDOMRange(editor, target)
      const rect = domRange.getBoundingClientRect()
      el.style.top = `${rect.top + window.pageYOffset + 24}px`
      el.style.left = `${rect.left + window.pageXOffset}px`
    }
  }, [chars.length, editor, index, search, target])

  return (
    <div className='slate-container'>
      <Slate
        className='slate-editor'
        editor={editor}
        initialValue={initialValue}
        onChange={() => {
          const { selection, children } = editor
          getTextFromEditor(children)

          if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection)
            const wordBefore = Editor.before(editor, start, { unit: 'word' })
            const before = wordBefore && Editor.before(editor, wordBefore)
            const beforeRange = before && Editor.range(editor, before, start)
            const beforeText = beforeRange && Editor.string(editor, beforeRange)
            const beforeMatch = beforeText && beforeText.match(/\$/)
            const after = Editor.after(editor, start)
            const afterRange = Editor.range(editor, start, after)
            const afterText = Editor.string(editor, afterRange)
            const afterMatch = afterText.match(/^(\s|$)/)

            if (beforeMatch && afterMatch) {
              setTarget(beforeRange)
              setSearch(beforeMatch[1])
              setIndex(0)
              return
            }
          }
          setTarget(null)
          setGlobalEditorChildren(children)
        }}
      >
        <div className={ emojiSelect.showBox ? '' : 'd-none' }>
          <EmojiPicker
            lazyLoadEmojis={true}
            searchDisabled
            suggestedEmojisMode={false}
            onEmojiClick={ onEmojiClick }
            previewConfig={{ showPreview: false }}
            height={300}
            width="75%"
          />
        </div>
        <Toolbar>
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          <MarkButton format="underline" icon="strikethrough_s" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
          <MarkButton format="emojis" icon="femoji_emotions" />
        </Toolbar>
        <Editable
            className='editable-slate-content'
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={t(enterSomeText)}
            spellCheck
            autoFocus
            onKeyDown={onKeyDown}
        />
        {target && (
          <Portal>
            <div
              ref={ref}
              style={{
                top: '-9999px',
                left: '-9999px',
                position: 'absolute',
                zIndex: 999,
                padding: '3px',
                background: 'white',
                borderRadius: '4px',
                boxShadow: '0 1px 5px rgba(0,0,0,.2)',
              }}
              data-cy="mentions-portal"
            >
              {chars.map((char, i) => (
                <div
                  key={char}
                  onClick={() => {
                    Transforms.select(editor, target)
                    insertMention(editor, char)
                    setTarget(null)
                  }}
                  style={{
                    padding: '1px 3px',
                    borderRadius: '3px',
                    background: i === index ? '#B4D5FF' : 'transparent',
                  }}
                >
                  {char}
                </div>
              ))}
            </div>
          </Portal>
        )}
      </Slate>
    </div>
  )
}




const withMentions = editor => {
  const { isInline, isVoid, markableVoid } = editor

  editor.isInline = element => {
    return element.type === 'mention' ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'mention' ? true : isVoid(element)
  }

  editor.markableVoid = element => {
    return element.type === 'mention' || markableVoid(element)
  }

  return editor
}

const insertMention = (editor, character) => {
  const mention = {
    type: 'mention',
    character,
    children: [{ text: '' }]
  }
  Transforms.insertNodes(editor, mention)
  Transforms.move(editor)
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  )

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const Element = props => {
  const { attributes, children, element } = props
  
  const style = { textAlign: element.align }
  switch (element.type) {
    case 'mention':
      return <Mention {...props} />
    case 'bulleted-list':
      return (
        <ul className='mb-0' style={style} {...attributes}>
          <li>{children}</li>
        </ul>
      )
    default:
      return (
        <p className='default-element mb-0' style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

const Mention = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  const isVariable = CHARACTERS.includes(element.character)
  let varChar;
  if(isVariable){
    varChar = `$${element.character}$`
  }
  const style = {
    padding: isVariable ? '2px 2px 1px' : '0',
    margin: isVariable ? '0 5px' : '0',
    color: '#263c7a',
    verticalAlign: 'baseline',
    display: 'inline-block',
    borderRadius: '4px',
    backgroundColor: isVariable ? '#eee' : 'transparent',
    fontSize: isVariable ? '0.9rem' : '1.4rem',
    fontWeight: 500,
    boxShadow: selected && focused ? '0 0 0 1px #B4D5FF' : 'none',
  }
  // See if our empty text child has any styling marks applied and apply those
  if (element.children[0].bold) {
    style.fontWeight = 'bold'
  }
  if (element.children[0].italic) {
    style.fontStyle = 'italic'
  }
  return (
    isVariable
    ? <span
        {...attributes}
        contentEditable={false}
        data-cy={`mention-${element.character.replace(' ', '-')}`}
        style={style}
      > {varChar} {children} </span>
    : <span
        {...attributes}
        contentEditable={false}
        data-cy={`mention-${element.character.replace(' ', '-')}`}
        style={style}
      >
        {element.character}
        {children}
      </span>
  )
}


const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive( editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type' )}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const MarkButton = ({ format, icon }) => {
  const { emojiSelect, setEmojiSelect } = useContext(ElementContext);
  const editor = useSlate()
  
  const handleEmojiBox = (event) => {
    event.target.textContent === 'femoji_emotions' && setEmojiSelect({ showBox: !emojiSelect.showBox })
  }

  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
      onClick={ handleEmojiBox }
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const CHARACTERS = [
  'NOMBRE',
  'APELLIDO',
  'NUMERO',
  'PAIS',
  'EMPRESA',
  'CARGO',
  'OBSERVACIONES',
  // 'DEUDA',
  // 'FECHAVENCIMIENTO'
]

const initialValue = [
  {
    type: 'paragraph',
    children: [
      { text: '' }
    ]
  }
]

export default RichTextExample