import React, { useState } from 'react';
import { FronteggProvider } from '@frontegg/react';
import Application from './Application';
import { getEnv } from './helpers/globalSettings';
import MainLoader from './components/MainLoader';
import { fetchBackend } from './helpers/fetch';
// import socket from './helpers/Socket';
import './styles/modal.css';


const themeOptions = {
  loginBox: {
      submitButtonTheme: {
          base: {
              backgroundColor: '#263c7a',
              color: '#fff',
              borderColor: 'transparent'
          }
      },
      inputTheme: {
          base: {
              borderColor: '#66b6ff',
          }
      }
  }
}


const App = () => {
  
  const [fronteggBaseUrl, setFronteggBaseUrl] = useState(null)
  const [pythonBackendUrl, setPythonBackendUrl] = useState(null)

  // socket.on('connect', () => {
  //   console.log('Conectado')
  // })
  
  // socket.on('disconnect', () => {
  //   console.log('Desconectado del servidor')
  // })

  const getFronteggUrl = async(field) => {
    // Validar token
    const verifyToken = await fetchBackend('verify-token')
    const verifyResponse = await verifyToken.json()
    
    if( verifyResponse.ok ){
      const getAllEnvDataFromDB = await getEnv()
      const fronteggUrl = await getAllEnvDataFromDB[field]
      const pythonBackendUrl = await getAllEnvDataFromDB['REACT_APP_PYTHON_BACKEND_URL']
  
      setFronteggBaseUrl(fronteggUrl)
      setPythonBackendUrl(pythonBackendUrl)
    } else {
      const generateToken = await fetchBackend('generate-token')
      const tokenResponse = await generateToken.json()
      const token = await tokenResponse.token
      localStorage.setItem('hbg-token', token)

      window.location.replace('/?plan=trial')
    }
  }

  getFronteggUrl('REACT_APP_FRONTEGG_API')
  
  
  const contextOptions = {
    baseUrl: fronteggBaseUrl
  };


  return  fronteggBaseUrl ? (
    <FronteggProvider contextOptions={contextOptions} themeOptions={themeOptions}>
        <Application pythonBackendUrl={pythonBackendUrl} />
    </FronteggProvider>
  ) : (
    <>
      <MainLoader />
    </>
  )
};

export default App;